<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="标题">
          <el-input v-model.trim="searchData.title" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            style="width: 250px"
            size="small"
            v-model="searchData.publishTimeRange"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.public_list_add)"
      style="margin-bottom: 20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'AttorneyPublicAdd' })"
    >
      添加
    </el-button>
    <el-divider></el-divider>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
  </div>
</template>

<script>
import { getListAPI, delAPI, modifyIsPublishAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

const columns = [
  {
    label: '标题',
    prop: 'title',
    minWidth: '250',
    customRender(h, row) {
      return <p>{row['title'] || '-'}</p>
    }
  },
  {
    label: '发布时间',
    prop: 'publish_date',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['publish_date']}</p>
    }
  },
  {
    label: '是否显示',
    prop: 'is_publish',
    minWidth: '120',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['status']}>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.public_list_status)}
            v-model={row['is_publish']}
            active-value={1}
            inactive-value={0}
            onChange={() => this.updateIsPublish(row)}
          ></el-switch>
        </p>
      )
    }
  },
  {
    label: '操作',
    fixed: 'right',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.public_list_edit)}
            style="margin-right: 10px;"
            type="primary"
            onClick={() =>
              this.$router.push({
                name: 'AttorneyPublicEdit',
                params: { id: row['id'] || undefined }
              })
            }
          >
            修改
          </el-link>
          <el-link v-show={this.isShowBtn(this.AUTH_BTN.public_list_del)} type="danger" onClick={() => this.goRemove(row['id'])}>
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [],
      memberList: [
        {
          id: 0,
          title: '请选择'
        }
      ],
      searchData: {
        title: '',
        publishTimeRange: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
    // this.getAllMember()
  },

  methods: {
    async goRemove(id) {
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    async getList() {
      let pageParams = { page: this.currentPage, page_size: this.page_size }
      let params = Object.assign(pageParams, this.searchData)
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    goEdit(id) {
      this.$router.push({ name: 'LegalPublicityEdit', params: { id: id || undefined } })
    },

    async updateIsPublish(row) {
      const { id, is_publish } = row
      await modifyIsPublishAPI({ id, is_publish })
      this.getList()
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }

  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
